import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../SharedComponents/SearchSelectField"
import "./ClearanceRecord.scss";
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { initialClearanceRecord, mockSong } from "./config";
import { checkBoxOptionsLeft ,checkBoxOptionsRightEdit } from "./config";
import { checkBoxOptionsRight } from "./config";
import messages from '../../../Common/Messages.json';
import axios from 'axios';
import Popover from '@material-ui/core/Popover';
import {validateNumbersOnly} from "../../../Common/Helper";
import { clearanceRecordPermissionsConfig } from "../../EpisodeAndClearence/EpisodeAndClearenceDetails/Config";
import MessageModal from '../../SharedComponents/MessageModal';
import SearchFieldWithAddValue from '../../../components/SharedComponents/SearchFieldWithAddValue'; 
import EditSongDetails from '../../EpisodeAndClearence/EditSong/EditSongDetails';

class ClearanceRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postInitiated: false,
            episodeOptions: [],
            loadingEpisodes: false,
            songOptions: [],
            adCardOptions: [],
            searchResult: [],
            seasonOptions: [],
            isFetchingSeason:false,
            showsOptions: [],
            clearanceRecordData: this.formatClearanceDetails(JSON.parse(JSON.stringify({ ...initialClearanceRecord }))),
            useOptions: [],
            checkBoxOptionsLeft: checkBoxOptionsLeft,
            checkBoxOptionsRight: this.props?.mode === 'New' ? [...checkBoxOptionsRight] : [...checkBoxOptionsRightEdit],
            isPosting: false,
            performerOptions: [],
            clearanceOptions: [],
            clearanceRepOptions: [],
            performerList: [],
            songUse: [],
            division_id: null,
            isDataLoading: false,
            searchCancelToken: null,
            isFetchingShow: false,
            isSongFetching: [],
            isPerformerFetching: [],
            customNotes:null,
            deletedSongs: [],
            songListData:[],
            clearanceRecordPermissionsConfig: clearanceRecordPermissionsConfig,
            durationValid: null,
            selectedRestrictedSong: false,
            newSongPopup: false,
            proposedNewSong: null,
            selectSong: null,
            searchSelect: true,
            isSendEmail: false,
            isActiveMusicSupervisor: false,
            isMsChecked: "",
            dialogBox: "",
            newSongRowIndex: null,
            loadingSongs:false,
            displayAddNewSongOption: false,
            searchedSongString: null,
            searchedSongIndex: null
        };
    };

    formatClearanceDetails = (response) => {
        if (response.songs_list?.length === 0) {
            response.songs_list.push(JSON.parse(JSON.stringify({ ...mockSong })))
            return response;
        } else {
            let newSongList = response?.songs_list?.map(item => {
                let newItem = { ...item }
                newItem.selected_song = {
                    'value': item?.song_id,
                    'text': `${item?.song_title}${item?.writer?.length > 0 ? '('+item?.writer?.map(ele => ele?.writer_name ? ele?.writer_name : ''+ele?.affiliation_name ? '-'+ele?.affiliation_name : '')+')' : ''}` 
                }
                newItem.selected_performer = {
                    'value': item?.performer_id,
                    'text': item?.performer_name
                }               
                newItem.min = item?.duration ? item?.duration?.split(":")[0] : null
                newItem.sec= item?.duration ? item?.duration?.split(":")[1] : null
                delete newItem.duration
                return newItem
            })
            response.songs_list = newSongList
            return response
        }
    }

    componentDidMount() {
        if(this.props?.clearanceRecordsFilter && this.props.mode === "New"){
            this.setState({clearanceRecordsFilter:this.props?.clearanceRecordsFilter},()=>{
                if(this.state.clearanceRecordsFilter?.selectedShow){
                    this.getSeasons(this.state.clearanceRecordsFilter?.selectedShow?.value,this.props.mode);
                }else{
                    if(this.state.clearanceRecordsFilter?.selectedSeason){
                        this.getEntity("seasonOptions", "SEASON", "null");
                    }
                }
                this.setState((prevState) => ({
                    clearanceRecordData: {
                        ...prevState.clearanceRecordData,
                        ["show_id"]:this.state?.clearanceRecordsFilter?.selectedShow?this.state?.clearanceRecordsFilter?.selectedShow?.value:null,
                        ["season_id"]: this.props.clearanceRecordsFilter?.selectedSeason?this.props.clearanceRecordsFilter?.selectedSeason:null,
                        ["show_season_episode_id"]: this.props.clearanceRecordsFilter?.selectedEpisode?this.props.clearanceRecordsFilter?.selectedEpisode:null,
                        ["selected_show"]:this.props.clearanceRecordsFilter?.selectedShow?this.props.clearanceRecordsFilter?.selectedShow:null,
                        ["show_name"]:this.state?.clearanceRecordsFilter?.selectedShow?this.state?.clearanceRecordsFilter?.selectedShow?.text:null,
                        ["scene"]:this.state?.clearanceRecordsFilter?.scene?this.state?.clearanceRecordsFilter?.scene:null,
                    },
                    division_id: this.props.clearanceRecordsFilter?.selectedShow?.division_id,
                    postInitiated: false
                }),()=>{
                    if(this.props?.clearanceRecordsFilter?.selectedPerformer){
                        this.getEntity("performerOptions", "PERFORMER", "null", this.props.mode);
                    }
                    if(this.props?.clearanceRecordsFilter?.selectedUses){
                        this.getEntity("useOptions", "MUSIC_USES", "",this.props.mode);
                    }
                    let temp_songs_list=JSON.parse(JSON.stringify(this.state?.clearanceRecordData?.songs_list));
                    if(this.props?.clearanceRecordsFilter?.duration){
                        temp_songs_list[0].min = this.props?.clearanceRecordsFilter?.duration?(this.props?.clearanceRecordsFilter?.duration?.includes(" ")|| this.props?.clearanceRecordsFilter?.duration?.includes(":")) ? (Number(this.props?.clearanceRecordsFilter?.duration?.split(" ")[0])||Number(this.props?.clearanceRecordsFilter?.duration?.split(":")[0])) : this.props?.clearanceRecordsFilter?.duration:null;
                        temp_songs_list[0].sec = this.props?.clearanceRecordsFilter?.duration?(this.props?.clearanceRecordsFilter?.duration?.includes(" ")|| this.props?.clearanceRecordsFilter?.duration?.includes(":")) ? (Number(this.props?.clearanceRecordsFilter?.duration?.split(" ")[1])||Number(this.props?.clearanceRecordsFilter?.duration?.split(":")[1])) : this.props?.clearanceRecordsFilter?.duration:null;
                    }
                    this.setState((prevState) => ({
                        clearanceRecordData: {
                            ...prevState.clearanceRecordData,
                            ["songs_list"]:temp_songs_list
                    }}))
                });
                
            });
        }
        this.getStaticData("lu_ad_card", "adCardOptions");
        if(this.props.mode!=="New" || !this.props?.clearanceRecordsFilter?.selectedUses){
            this.getEntity("useOptions", "MUSIC_USES", "");
        }
        if (this.props?.from) {
            this.getShowLists("");
        }
        if (this.props.mode === "Edit") {
            this.getClearanceData(this.props?.clearenceId);
        } else {
            this.getSongs(" ", 0, "fromDidMount")
        }
        if (this.props.isClearanceRecord) {
            this.setState({ division_id: this.props?.clearance_divisionId })
        }
        // this.getStaticData("lu_performer","performer");
        if (this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor' && item?.division_id === this.props?.clearance_divisionId)) {
            this.setState({ isActiveMusicSupervisor: true });
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id)
            && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor' && item?.division_id === this.props?.clearance_divisionId)) {
            this.setState({ isActiveMusicSupervisor: true });
        }
    }

    getEntity = (entity, entitytype, searchString, mode=null) => {
        if (entitytype === 'MUSIC_USES') {
            this.setState({ isFetchingMusicUses: true });
        }
        if (entitytype === 'SEASON') {
            this.setState({ isFetchingSeason: true });
        }
        
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = [];
                if (entitytype === 'MUSIC_USES') {
                    formattedList = response?.data?.map(item => ({ value: item.music_uses_id, label: item.abbreviation }));
                    if(mode==="New"){
                        let temp_songs_list=JSON.parse(JSON.stringify(this.state?.clearanceRecordData?.songs_list));
                        temp_songs_list[0]["music_uses_id"]=this.props.clearanceRecordsFilter?.selectedUses;
                        this.setState((prevState) => ({
                            clearanceRecordData: {
                                ...prevState.clearanceRecordData,
                                ["songs_list"]: temp_songs_list
                            }
                        }));
                    }
                }
                if (entitytype === 'SEASON') {
                    formattedList = response?.data?.map(item => ({ value: item.season_id, label: item.season_name }));
                }
                if (entitytype === 'PERFORMER') {
                    if(mode==="New"){
                        formattedList = response?.data?.map(item => ({ value: item?.performer_id, text: item?.performer_name }));
                        let temp_songs_list=JSON.parse(JSON.stringify(this.state?.clearanceRecordData?.songs_list));
                        let selected_performer = formattedList?.find(item => item?.value === this.props.clearanceRecordsFilter?.selectedPerformer);
                        console.log("selected_performer",selected_performer);
                        temp_songs_list[0]["selected_performer"]=selected_performer;
                        temp_songs_list[0]["performer_id"]=selected_performer.value;
                        temp_songs_list[0]["performer_name"]=selected_performer.text;
                        this.setState((prevState) => ({
                            clearanceRecordData: {
                                ...prevState.clearanceRecordData,
                                ["songs_list"]: temp_songs_list
                            }
                        }));
                    }
                }
                this.setState({ [entity]: formattedList, isFetchingMusicUses: false,isFetchingSeason: false });
            },
                (err) => {
                    if (entitytype === 'MUSIC_USES') {
                        this.setState({ isFetchingMusicUses: false });
                    }
                    if (entitytype === 'SEASON') {
                        this.setState({ isFetchingSeason: false });
                    }
                    
                    console.log("Error in fetching entities:", err)
                })
    }

    getPerformer = (song_id, index) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/songDetails?song_id=${song_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let songDoNotUse = response?.data[0]?.song_is_do_not_use
                let restrictedSong = response?.data[0]?.is_all_promo_use_restricted
                let formattedList = response.data[0]?.performers_list.length > 0 ?
                response.data[0]?.performers_list?.map(item => ({ value: item.performer_id, text: item.performer_name })) : [];
                if (restrictedSong == 1) {
                    this.setState({ selectedRestrictedSong: true, index: index })
                }
                if (formattedList.length > 0) {
                    this.handleSongDataChange("performer", formattedList[0], index)
                } else {
                    this.handleSongDataChange("performer", null, index)
                }
                let performer = [...this.state.performerList]
                performer[index] = formattedList
                let songUseArr = [...this.state.songUse]
                songUseArr[index] = songDoNotUse
                this.setState({ performerList: formattedList, songUse: songDoNotUse });
            },
                (err) => {
                    this.handleSongDataChange("performer", null, index)
                    console.log("Error in fetching performer Details:", err)
                })
    }
    postPerformer(performerName,index){
        let data = JSON.parse(JSON.stringify({ ...this.state?.clearanceRecordData}))
        data.songs_list[index]['performer_id'] = null;
        let ClearancePerformer = { id:null,name:performerName, is_active: 1, table_name: "lu_performer" };
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/staticData`,
        ClearancePerformer,
        this.props?.userContext?.active_tenant?.tenant_id)
        .then(
            (response) => {
                if (response.data.error) {
                    this.props?.notificationComponent(true, 'fail')
                } else {               
                    data.songs_list[index]['performer_id'] = response.data?.[0]?.id || null;
                    data.songs_list[index]['performer_name'] = performerName || null;
                    data.songs_list[index]['selected_performer'] = performerName;
                    data.songs_list[index]['songLoader'] = false;
                    data.is_songs_updated = 1
                    this.setState({clearanceRecordData: data,postInitiated: false}, 
                        ()=>{
                         
                         this.props?.fieldChanged(true, "change")
                        })
                }
            },
            (err) => {this.props?.notificationComponent(true, 'fail')}
        )

    }

    getPerformerDetails = (searchString, index) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        let flags = [...this.state.isPerformerFetching]
        flags[index] = true
        this.setState({ isPerformerFetching: flags })
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=PERFORMER&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
            .then((res) => {
                let formattedList = res?.data?.map(item => ({ value: item?.performer_id, text: item?.performer_name }));
                if (this.state.performerList[index]?.length > 0) {
                    this.state.performerList[index].map((item) => {
                        formattedList.unshift(item)
                    })
                }
                let performer = [...this.state.performerOptions]
                performer[index] = formattedList
                let flags = [...this.state.isPerformerFetching]
                flags[index] = false
                this.setState({ isPerformerFetching: flags, performerOptions: performer })
            })

            .catch((err) => {
                let flags = [...this.state.isPerformerFetching]
                flags[index] = false
                this.setState({ isPerformerFetching: flags })
                console.log("error")
            });
    };

    getSongs = (value, index, origin = null) => {
        let searchObj = {
            'data_type': 'SONGS',
            'search_string': value,
        }
        let flags = [...this.state.isSongFetching]
        if(origin==="fromDidMount"){
            flags[index] = false;
        }else{
            flags[index] = true;
        }
        this.setState({ displayAddNewSongOption: false, isSongFetching: flags, searchedSongString: value, searchedSongIndex: index });
        if (this.state.searchCancelToken != null){
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        }
        var cancelToken = axios.CancelToken.source();
        this.setState({searchCancelToken: cancelToken },()=>{
            ClearTrackService.getDataParamsWithCancel(Constants.ClearTrackServiceBaseUrl + '/globalSearch', searchObj, this.props.userContext?.active_tenant?.tenant_id, null, cancelToken)
            .then(response => {
                let exsitingSongs = this.state.mainTitleClearanceData?.songs_list?.map(item => item?.song_id)
                let formattedList = response?.data?.data?.map(item => ({ value: item?.id?.split('_')[1], text: item?.name }))
                this.setState({
                    songOptions: formattedList?.filter(item => !exsitingSongs?.includes(item?.value)),
                    displayAddNewSongOption:true
                }, () => {
                    flags[index] = false
                    this.setState({ selectSong: this.state?.newSongId?.toString()?.toLowerCase(), isSongFetching: flags });
                    if (origin === "fromNewSong") {
                        this.handleSongDataChange("song",
                            this.state.songOptions.find(songid => {
                                return songid.value == this.state.newSongId
                            })
                            , index)
                    }

                })
            },
                (err) => {
                    this.setState({ displayAddNewSongOption:false })
                }
            )
        });
        // if (value) {
        
    // }
}
newSongPopup() {
    this.setState({ newSongPopup: false, proposedNewSong : null });
}
newSongId(songId, songTitle = null, index = 0) {
    this.setState({ newSongId: songId, searchSelect: false,loadingSongs:true }, () => {
        this.setState({ newSongPopup: false });
        this.getSongs(songTitle, index, "fromNewSong");
    });
}

valueSelected(index) {
    this.getSongs(this.state?.newSongId, index);
}
getShowLists = (value) => {
    if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    // if (value) {
    this.setState({ isFetchingShow: true })
    ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SHOW&searchString=${value}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
            let formattedList = response?.data?.map(item => ({ value: item.show_id, text: item.show_name, division_id: item.division_id }));
            this.setState({ showsOptions: formattedList, isFetchingShow: false })
        },
            (err) => {
                console.log("Error in fetching entities:", err)
            })
    // }

}

getClearanceData = (clearance_id) => {
    this.setState({ isDataLoading: true });
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/clearance?clearance_id=${clearance_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.clearance_divisionId)
        .then(response => {
            let clearanceList = response?.data[0]
            let sondDonotArr = clearanceList?.songs_list?.map(item => item?.is_do_not_use)
            let songListData = clearanceList?.songs_list
            this.setState({
                songListData: songListData,
                clearanceRecordData: this.formatClearanceDetails(clearanceList),
                isDataLoading: false,
                division_id: response?.data[0]?.division_id,
                songUse: sondDonotArr
            })
            if (clearanceList?.show_id) {
                this.getSeasons(clearanceList?.show_id)
            }
        },
            (err) => {
                console.log("Error in fetching entities:", err)
                this.setState({ isDataLoading: false });
            })
}
getSeasons = (showId,mode=null) => {
    this.setState({ isFetchingSeason: true })
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${showId}`, this.props.userContext?.active_tenant?.tenant_id, this.state.division_id)
        .then(response => {
            let formattedList = response.data[0]?.seasons?.map(item => ({ value: item.season_id, label: item.season_name, show_season_id: item.show_season_id }));
            this.setState({ seasonOptions: formattedList, isFetchingSeason: false },()=>{
                if(mode==="New"){
                    let selectedShowSeasontemp = this.state.seasonOptions?.find(item => item?.value == this.state.clearanceRecordsFilter?.selectedSeason)?.show_season_id;
                    console.log("this.state.seasonOptions",this.state.seasonOptions);
                    console.log("this.state.clearanceRecordsFilter?.selectedSeason",this.state.clearanceRecordsFilter?.selectedSeason);
                    this.setState((prevState) => ({
                        clearanceRecordData: {
                            ...prevState.clearanceRecordData,
                            ["show_season_id"]: selectedShowSeasontemp,
                        }
                    }));
                    this.getShowBasedEpisodes(selectedShowSeasontemp,mode);
                }
            });

        },
            (err) => {
                console.log("Error in fetching Tenant Details:", err)
            })
}

getShowBasedEpisodes = (showSeasonId,mode=null) => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes?show_season_id=${showSeasonId}`, this.props.userContext?.active_tenant?.tenant_id, this.state.division_id)
        .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.show_season_episode_id, label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}` }));
            this.setState({ episodeOptions: formattedList, loadingEpisodes: false },()=>{
                if(mode==="New"){
                    this.setState((prevState) => ({
                        clearanceRecordData: {
                            ...prevState.clearanceRecordData,
                            ["episode_title"]: this.state.episodeOptions?.find(item => item?.value === this.props.clearanceRecordsFilter?.selectedEpisode)?.label
                        }
                    }));
                }
            });
        },
            (err) => {
                console.log("Error in fetching Details:", err)
            })
}

getStaticData = (tableName, fieldName) => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${tableName}`, null)
        .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
            this.setState({ [fieldName]: formattedList, });
        },
            (err) => {
                console.log("Error in fetching Details:", err)
            })
}
handleModalAndSubmit = () => {
    let clearanceRecordData = this.state.clearanceRecordData;
    if (this.state?.isMsChecked == "true") {
        this.setState({ dialogBox: "checked" });
    }
    else if (this.state?.isMsChecked == "false") {
        this.setState({ dialogBox: "unchecked" });
    } else {
        this.handleSubmit();
    }
}
handleSubmit = () => {
    return new Promise((resolve, reject) => {
        this.setState({ postInitiated: true })
        let data = { ...this.state.clearanceRecordData };
        if (this.props?.isClearanceRecord) {
            data.show_id = this.props.showId
            data.show_season_id = this.props.show_season_id
            data.season_id = this.props.seasonId
            data.show_season_episode_id = +this.props.episodeId
            data.show_name = this.props.show_name
            data.episode_title = this.props.episode_name
        } else {
            data.show_season_id = this.state.clearanceRecordData.show_season_id || this.props.show_season_id
            data.clearance_rep_id = this.props?.clearance_rep_id || null
        }
        if (this.validateFlagError(data)) {
            this.postClearanceData(data).then(() => resolve()).catch(reject);
        }
    });
}

validateFlagError = (data) => {
    let canSubmit = false;
    canSubmit = (
        data?.show_id && data?.season_id && data?.show_season_episode_id &&
        (data?.songs_list?.length > 0 && this.validateSongs(data?.songs_list)))
    return canSubmit;
}

validateSongs(songList) {
    let canSubmit = true;
    let i = 0;
    for (i; i < songList?.length; i++) {
        let obj = songList[i];
        if ((!obj.song_id || !obj.song_title ||
            !obj.music_uses_id) || (this.state.useOptions?.find(item => item?.value === obj.music_uses_id)?.label !== 'Custom Use' ?
                (obj?.sec ? !(obj?.sec < 60 && validateNumbersOnly(obj?.sec)) : false) || (obj?.min ? !(validateNumbersOnly(obj?.min)) : false) : false)) {
            canSubmit = false;
            break;
        }
    }
    return canSubmit;
}

    postClearanceData = (postJson) => {
        return new Promise((resolve, reject) => {
            this.setState({ isPosting: true, postInitiated: true })
            if (this.props?.isClearanceRecord) {
                postJson.show_id = this.props.showId
                postJson.show_season_id = this.props.show_season_id
                postJson.season_id = this.props.seasonId
                postJson.show_season_episode_id = this.props.episodeId
                postJson.show_name = this.props.show_name
                postJson.episode_title = this.props.episode_name
            }
            if (this.state.deletedSongs.length > 0) {
                postJson.songs_list = [...postJson.songs_list, ...this.state.deletedSongs]
            }
            for (let i = 0; i < postJson.songs_list?.length; i++) {
                postJson.songs_list[i].duration = `${postJson?.songs_list[i]?.min ? postJson.songs_list[i].min : 0}:${postJson.songs_list[i]?.sec ? postJson.songs_list[i]?.sec : 0}`
                delete postJson.songs_list[i].min
                delete postJson.songs_list[i].sec
            }
            let extensionUrl = '/clearance'
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id, this.state.division_id)
                .then((response) => {
                    if (!response.data.error) {
                        this.setState({ isPosting: false, postInitiated: false })
                        this.props?.fieldChanged(false, "error");
                        this.props?.notificationComponent(true, "success")
                        if (this.props?.handleSubmit) {
                            this.props?.handleSubmit(postJson.show_season_id, postJson.show_season_episode_id, response.data[0]?.clearance_id)
                        }
                        // if (this.props?.updateClearanceDetails) {
                        //     let updatedDetails = [];
                        //     updatedDetails.push(postJson)
                        //     this.props?.updateClearanceDetails(updatedDetails);
                        // }
                        //this.props?.toggleRefreshPage('EpisodeClearance');
                        setTimeout(() => {
                            if (this.props?.mode === 'New' && this.props?.toggleRefreshClearanceList) {
                                this.props?.toggleRefreshClearanceList();
                            }
                            else {
                                if (this.props?.toggleRefreshPage) {
                                    this.props?.toggleRefreshPage('EpisodeClearance');
                                }
                            }
                        }, 2000)

                        resolve(response);
                    }
                    else {
                        this.setState({ isPosting: false, postInitiated: false })
                        this.props?.notificationComponent(true, "fail")
                        reject(new Error('Server responded with an error'));
                    }
                })
                .catch((err) => {
                    this.setState({ isPosting: false, postInitiated: false });
                    this.props?.notificationComponent(true, "fail");
                    console.log("Post agency details error: " + err);
                    reject(err); // Reject the promise with the caught error
                });
        });
    }

    handleChange = (field, value) => {
        this.setState((prevState) => ({
            clearanceRecordData: {
                ...prevState.clearanceRecordData,
                [field]: value,
            },
            postInitiated: false
        }));
        this.props?.fieldChanged(true, "change")
    }


    onCheckboxChange = (value, field) => {
        this.setState((prevState) => ({
            clearanceRecordData: {
                ...prevState.clearanceRecordData,
                [field]: value ? 1 : 0
            },
            postInitiated: false,
        }));
        if (field === "is_ms_complete") 
            this.setState({ isMsChecked:!value?"false":"true" });
            this.props?.fieldChanged(true, "change");
    }

    removeSong(index) {
        let data = {...this.state.clearanceRecordData}
        let existingSongList=[...this.state.songListData]
        let deletedItem = data?.songs_list[index]
        if (deletedItem?.clearance_song_id) {
            deletedItem.is_delete = 1
            let deletedSongs = [...this.state.deletedSongs, deletedItem]
            this.setState({ deletedSongs: deletedSongs })
            data.is_songs_updated = 1
        }
        data.songs_list.splice(index, 1)
        let deleteExistingItem = existingSongList.filter(item =>item.song_id !== deletedItem.song_id)
        this.setState({ clearanceRecordData: data, postInitiated: false ,songListData:deleteExistingItem})
        this.props?.fieldChanged(true, "change")
    }

    addSong() {
        let data = null;
        data ={...this.state.clearanceRecordData}
        data.songs_list.push(JSON.parse(JSON.stringify({ ...mockSong })))
        this.setState({ clearanceRecordData: data , postInitiated:false}, () => {
            this.getSongs(" ",data.length)
        })
        this.props?.fieldChanged(true, "change")
    }

    handleSongDataChange = (fieldName, value, index) => {
        let data =  { ...this.state.clearanceRecordData };
        
        if (fieldName === "song") {
            this.setState({ searchSelect: true })
            if (value?.optionType == 'new') {
                
                this.setState({ newSongPopup: true, proposedNewSong: value, newSongRowIndex: index })
                // this.postPerformer(value?.value, index)
            } else {
                if (value) {
                    data.songs_list[index][fieldName + '_id'] = value?.value || null;
                    data.songs_list[index][fieldName + '_title'] = value?.text || null;
                    data.songs_list[index]["selected_song"] = value || null;
                    data.is_songs_updated = 1
                    data.songs_list[index]['songLoader'] = true
                    this.getPerformer(value?.value, index);
                    data.songs_list[index]['selected_song'] = value || null;
                    data.songs_list[index]["performer_id"] = null;
                    data.songs_list[index]["performer_name"] = null;
                    data.songs_list[index]["selected_performer"] = null;
                } else {
                    data.songs_list[index][fieldName + '_id'] = null;
                    data.songs_list[index][fieldName + '_title'] = null;
                    data.songs_list[index]['selected_song'] = null;
                    data.songs_list[index]["performer_id"] = null;
                    data.songs_list[index]["performer_name"] = null;
                    data.songs_list[index]["selected_performer"] = null;
                    data.songs_list[index]['songLoader'] = false;
                    this.setState({ performerOptions: [] })
                }
                this.setState({ proposedNewSong: null, newSongRowIndex: null });
            }
            this.setState({loadingSongs:false})
        }
        else if (fieldName === "performer" && value?.optionType == 'new') {
            this.postPerformer(value?.value, index)
        }
        else if (fieldName === "performer") {
            data.songs_list[index][fieldName + '_id'] = value?.value || null;
            data.songs_list[index][fieldName + '_name'] = value?.text || null;
            data.songs_list[index]['selected_performer'] = value;
            data.songs_list[index]['songLoader'] = false;
            data.is_songs_updated = 1
        }
        else if (fieldName === "min" || fieldName === "sec") {
            data.songs_list[index][fieldName] = value
            data.is_songs_updated = 1
        }
        else if (fieldName === "music_uses_id") {
            data.songs_list[index][fieldName] = value
            data.is_songs_updated = 1
            data.songs_list[index]['custom_use'] = this.state.useOptions?.find(item => item?.value === value)?.label !== 'Custom Use' ? null : data.songs_list[index]['custom_use'] || null
        } else if (fieldName === 'custom_use') {
            data.songs_list[index][fieldName] = value
            data.songs_list[index]['customPopover'] = null
        }
        else {
            data.songs_list[index][fieldName] = value
            data.is_songs_updated = 1
        }
        this.setState({ clearanceRecordData: data,loadingSongs:false }, () => {
            if (fieldName === 'customPopover') {
                this.setState({ customNotes: value ? data.songs_list[index]['custom_use'] : null })
            }
            if (fieldName === 'custom_use') {
                this.setState({ customNotes: null })
            }
        })
        this.props?.fieldChanged(true, "change")
    }

    closPopup = () => {
        this.setState({ selectedRestrictedSong: false })
    }
    postSendDataEmail = () => {
        this.setState({ isSendEmail: true });
        let payload = {
          letter_type: "msepisodecomplete",
          show_season_episode_id: this.state.clearanceRecordData.show_season_episode_id,
        };
        ClearTrackService
          .postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/sendEmail`,
            payload,
            this.props.userContext?.active_tenant?.tenant_id,
            this.state.division_id
          )
          .then((response) => {
            this.setState({isSendEmail: false});
            this.setState({dialogBox:""});
          })
          .catch((err) => {
            this.setState({isSendEmail: false});
            this.setState({dialogBox:""});
          });
      };
    CancelModalPopup = () => {
       let clearanceRecordData = this.state.clearanceRecordData;
       if(this.state?.dialogBox ==="checked"){
       clearanceRecordData.is_ms_complete=0;}
       else clearanceRecordData.is_ms_complete=1;
        this.setState({ dialogBox: "", clearanceRecordData: clearanceRecordData })
        this.setState({isMsChecked:""});
    }

    ConfirmModalPopUp = async () => {
        let clearanceRecordData = this.state.clearanceRecordData;
        await this.handleSubmit();
        if(this.state?.dialogBox ==="unchecked"){
            clearanceRecordData.is_ms_complete=0;  
        }
        if(this.state?.dialogBox ==="checked"){
            this.postSendDataEmail();
        }
        this.setState({dialogBox:""});
}
    removeSelectedRestrictedSong = () => {
        let clearanceRecordData = this.state.clearanceRecordData
        clearanceRecordData.songs_list[this.state.index].song_id = null
        clearanceRecordData.songs_list[this.state.index].selected_song = {}
        clearanceRecordData.songs_list[this.state.index].selected_performer = null
        this.setState({
            selectedRestrictedSong: false, 
            clearanceRecordData: clearanceRecordData
        })
    }
    onDropdownClose = () => {
        this.setState({songOptions: [], searchedSongIndex: null, searchedSongString: null})
    }
    render() {
        let viewApplyHVPermission = this.props?.applyHVPermission?.view == 1 ? true : false;
        let comfirmModalTitle = this.state.selectedRestrictedSong == 1 ? "Restricted Song Selected" : "";
        let comfirmationModalTitle = this.state?.dialogBox === "checked" ? "Confirm Here if Episode is Complete" : "";
        let unCheckComfirmationModalTitle = this.state?.dialogBox === "unchecked" ? "Confirm Here if Episode is Incomplete" : "";
        let confirmationMessage = this.state?.dialogBox === "checked" ? `Mark episode ${this.state?.clearanceRecordData.episode_title} 'music supervisor' complete?` : "";
        let unCheckConfirmationMessage = this.state?.dialogBox === "unchecked" ? `Set episode ${this.state?.clearanceRecordData.episode_title} to 'music supervisor' incomplete?` : "";
        let message = this.state.selectedRestrictedSong == 1 ? "Restricted Song has been selected" : " ";
        let checkBoxOptionsRight = viewApplyHVPermission ? this.state.checkBoxOptionsRight : this.state.checkBoxOptionsRight.filter((item) => item?.id != "is_apply_dollar_to_HV");
        let editApplyHVPermission = this.props.applyHVPermission?.edit == 1 ? true : false;
        let canEditLicensorInformation = this.props.clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "song/performerInfo" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name)));
        // this.props.mode === "Edit" ? canEditLicensorInformation = this.props?.commonPermissions?.find(item => item.permission_name === "Can edit a Song") : canEditLicensorInformation = this.props?.commonPermissions?.find(item => item.permission_name === "Can create a Clearance Record")
        let loadingFlag = this.state.isFetchingMusicUses || this.state.isDataLoading
        let data = { ...this.state.clearanceRecordData }
        let heading = this.state.clearanceRecordData.show_name ? "For "
            + this.state.clearanceRecordData.show_name + '('
            + this.state.clearanceRecordData?.season_name + ') ' +
            (this.state.clearanceRecordData.show_season_episode_id ? ", Ep. " +
                this.state.clearanceRecordData.show_season_episode_id : " ") +
            (this.state.clearanceRecordData.episode_title ? " - " +
                this.state.clearanceRecordData.episode_title + "." : ".") : " "
        let newMode = "For " + this.props?.show_name + ", Ep. " +
            this.props?.episode_name + " - " + this.props?.season_name + "."
        return (
            <>
                {loadingFlag ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                        // <div disabled={!canEditLicensorInformation}>
                        <div>
                            <MDBContainer fluid className="ModalContainer ClearanceRecordContainer">
                                {this.state.selectedRestrictedSong &&
                                    <MessageModal
                                        open={this.state.selectedRestrictedSong || false}
                                        title={comfirmModalTitle}
                                        message={message}
                                        hideCancel={false}
                                        primaryButtonText={"OK"}
                                        secondaryButtonText={"Cancel"}
                                        onConfirm={() => this.closPopup()}
                                        handleClose={(e) => this.removeSelectedRestrictedSong()} />
                                }
                                {
                                    this.state?.dialogBox &&
                                    <MessageModal
                                        open={this.state?.dialogBox || false}
                                        title={this.state?.dialogBox == "checked" ? comfirmationModalTitle : unCheckComfirmationModalTitle}
                                        message={this.state?.dialogBox == "checked" ? confirmationMessage : unCheckConfirmationMessage}
                                        hideCancel={false}
                                        primaryButtonText={this.state?.dialogBox == "checked" ?
                                            this.state.isPosting ? (
                                                <CircularProgress color="inherit" size={18} />
                                            ) : (
                                                    "OK"
                                                ) : "Ok"
                                        }
                                        secondaryButtonText={"Cancel"}
                                        onConfirm={() => {
                                            this.ConfirmModalPopUp();
                                        }}
                                        handleClose={(e) => this.CancelModalPopup()} />}
                                <div className="between">
                                    <div>
                                        {
                                            this.props?.from ? null :
                                                <BasicLabel
                                                    text={!this.props.isClearanceRecord ? heading : newMode}
                                                />
                                        }
                                    </div>
                                    <div>
                                        {this.props.mode === "Edit" && this.state.clearanceRecordData.clearance_id != null ? <BasicLabel text={"Clearance Id - " + this.state.clearanceRecordData.clearance_id} /> : null}
                                    </div>
                                </div>
                                {!this.props?.from ?
                                    null
                                    :
                                    <div>
                                        <MDBRow>
                                            <MDBCol md={4}>
                                                <SearchSelectField
                                                    id={"clearence-show"}
                                                    label={"Show"}
                                                    placeholder={'- Search -'}
                                                    options={this.state.showsOptions || []}
                                                    isMandatory={true}
                                                    showMandatory={this.state.postInitiated}
                                                    mandatoryWarning={messages.mandatoryWarning}
                                                    searchSelect={true}
                                                    loading={this.state.isFetchingShow}
                                                    detail_selected={this.state.clearanceRecordData?.selected_show}
                                                    width={'100%'}
                                                    valueSelected={(e, newValue) => {
                                                        this.handleChange('show_id', newValue ? newValue?.value : null)
                                                        this.handleChange('show_name', newValue ? newValue?.text : null)
                                                        this.handleChange('selected_show', newValue ? newValue : null)
                                                        this.handleChange('season_id', null)
                                                        this.handleChange('show_season_id', null)
                                                        this.handleChange('show_season_episode_id', null)
                                                        this.handleChange('episode_title', null)
                                                        this.setState({ seasonOptions: [], episodeOptions: [], division_id: newValue ? newValue?.division_id : null })
                                                        if (newValue) {
                                                            this.getSeasons(newValue.value);
                                                        }
                                                    }}
                                                    searchText={ev => {
                                                        if (ev.target.value !== "" && ev.target.value !== null) {
                                                            this.getShowLists(ev.target.value)
                                                        }

                                                    }}
                                                />
                                            </MDBCol>
                                            <MDBCol md={4}></MDBCol>
                                        </MDBRow>
                                        <MDBRow className="mt-1">
                                            <MDBCol md={2}>
                                                {(this.state.isFetchingSeason) ? <CircularProgress color="inherit" size={15} /> :
                                                    <SelectField
                                                        width={"100%"}
                                                        label="Season"
                                                        placeHolderText="- Select -"
                                                        isMandatory={true}
                                                        showMandatory={this.state.postInitiated}
                                                        mandatoryWarning={messages.mandatoryWarning}
                                                        value={data.season_id}
                                                        options={this.state.seasonOptions || []}
                                                        onChange={(e) => {
                                                            this.handleChange("season_id", e.target.value)
                                                            this.handleChange("show_season_episode_id", null);
                                                            this.handleChange("episode_title", null)
                                                            let selectedShowSeason = this.state.seasonOptions?.find(item => item?.value === e.target?.value)?.show_season_id
                                                            this.handleChange("show_season_id", selectedShowSeason ? selectedShowSeason : null)
                                                            this.setState({ episodeOptions: [] })
                                                            if (selectedShowSeason != "") {
                                                                this.setState({ loadingEpisodes: true })
                                                                this.getShowBasedEpisodes(selectedShowSeason)
                                                            }
                                                        }
                                                        }
                                                    />
                                                }
                                            </MDBCol>
                                            <MDBCol md={4}>
                                                {(this.state.loadingEpisodes) ? <CircularProgress color="inherit" size={15} /> :
                                                    <SelectField
                                                        label={"Episode"}
                                                        width={"100%"}
                                                        id={"episode"}
                                                        placeHolderText="- Select -"
                                                        isMandatory={true}
                                                        showMandatory={this.state.postInitiated}
                                                        mandatoryWarning={messages.mandatoryWarning}
                                                        value={data.show_season_episode_id}
                                                        options={this.state.episodeOptions || []}
                                                        onChange={(e, newValue) => {
                                                            this.handleChange("show_season_episode_id", e.target.value);
                                                            this.handleChange("episode_title", newValue?.props?.children)
                                                        }}
                                                    />
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                }
                                {this.state.durationValid === false ? <p className="f-color">Please enter Duration in format : max. 3 digits:max 2 digits</p> : null}
                                {(this.state.loadingSongs) ?
                                    <CircularProgress color="inherit" size={15} />
                                    :
                                    this.state.clearanceRecordData?.songs_list?.map((song, index) => (
                                        <MDBRow key={"dropdownrow" + index}>
                                            <MDBCol className="songsDropdown" md={4} disabled={this.state.songListData[index]?.song_id === song.song_id ? true : false}>
                                                <BasicLabel
                                                    text={`${index === 0 ? "Song(s)" : ""}`}
                                                />
                                                <SearchFieldWithAddValue
                                                    id={"songs" + index}
                                                    label={`${index === 0 ? "Song(s)" : ""}`}
                                                    placeholder={'- Search -'}
                                                    width={'100%'}
                                                    //value={this.state?.selectSong}
                                                    isMandatory={true}
                                                    showMandatory={this.state.postInitiated}
                                                    mandatoryWarning={messages.mandatoryWarning}
                                                    loading={this.state.isSongFetching[index]}
                                                    detail_selected={song?.song_title && song?.song_title?.trim() !== "" ? song?.song_title : this.state?.proposedNewSong?.value}
                                                    options={this.state.songOptions || []}
                                                    searchSelect={this.state.searchSelect}
                                                    onChange={(e, newValue) => this.handleSongDataChange("song", newValue, index)}
                                                    searchText={ev => {
                                                        // dont fire API if the user delete or not entered anything
                                                        if (ev.target.value !== "" && ev.target.value !== null) {
                                                            this.getSongs(ev.target.value, index)
                                                        }
                                                    }}

                                                    newLabel={'Song'}
                                                    displayAddNewSongOption={this.state.displayAddNewSongOption}
                                                    onDropdownClose = {this.onDropdownClose.bind(this)}
                                                />
                                                {
                                                    this.state.songUse[index] === 1
                                                        ?
                                                        <span>
                                                            <span class="donot"><i>DO NOT USE song</i> </span>
                                                        </span>
                                                        : ""
                                                }
                                            </MDBCol>
                                            <MDBCol md={3} id="performer">
                                                <>
                                                    <BasicLabel
                                                        text={index === 0 ? "Performer" : ""}
                                                    // isMandatory={true}
                                                    />
                                                    {/* disabled={(!song.song_id || this.state.songListData[index]?.song_id === song.song_id)?true:false}> */}
                                                    {song?.songLoader ? <CircularProgress size={15} /> :
                                                        <SearchFieldWithAddValue
                                                            id={"performer"}
                                                            newLabel={`${index === 0 ? "Perfomer" : ""}`}
                                                            placeholder={'- Search -'}
                                                            // isMandatory={true}
                                                            // showMandatory={this.state.postInitiated}
                                                            // mandatoryWarning={messages.mandatoryWarning}
                                                            options={this.state.performerOptions[index] || []}
                                                            searchSelect={true}
                                                            detail_selected={song?.selected_performer}
                                                            onChange={(e, newValue) => this.handleSongDataChange("performer", newValue, index)}
                                                            searchText={ev => {
                                                                if (ev.target.value !== "" && ev.target.value !== null) {
                                                                    this.getPerformerDetails(ev.target.value, index)
                                                                } else {
                                                                    let flags = [...this.state.isPerformerFetching]
                                                                    flags[index] = false
                                                                    this.setState({ isPerformerFetching: flags })
                                                                }
                                                            }}
                                                        />}
                                                </>
                                            </MDBCol>
                                            {this.state.useOptions?.find(item => item?.value === song.music_uses_id)?.label !== 'Custom Use' ? <MDBCol md={2} className='flex justify-content-between'>
                                                <BasicTextField
                                                    label={`${index === 0 ? "Min" : ""}`}
                                                    placeholder={"__"}
                                                    value={song.min}
                                                    fieldValid={song.min ? !validateNumbersOnly(song.min) : false}
                                                    inValidInput={"Invalid Min."}
                                                    onChange={(e) => this.handleSongDataChange("min", e.target.value.length > 0 ? e.target.value : null, index)} />
                                                <BasicTextField
                                                    value={':'}
                                                    label={`${index === 0 ? " " : ""}`}
                                                    showAsLabel={true} />
                                                <BasicTextField
                                                    label={`${index === 0 ? "Sec" : ""}`}
                                                    placeholder={"__"}
                                                    value={song.sec}
                                                    fieldValid={song.sec ? !(validateNumbersOnly(song.sec) && song.sec < 60) : false}
                                                    inValidInput={"Invalid Sec."}
                                                    onChange={(e) => this.handleSongDataChange("sec", e.target.value.length > 0 ? e.target.value : null, index)} />
                                            </MDBCol> :
                                                <MDBCol>
                                                    {index === 0 && <BasicLabel text={'Duration'} />}
                                                    <BasicButton
                                                        onClick={(e) => this.handleSongDataChange("customPopover", e.currentTarget, index)}
                                                        variant="outlined"
                                                        text={"View"}
                                                    />
                                                    <Popover
                                                        id={index + song?.music_uses_id}
                                                        open={Boolean(song?.customPopover)}
                                                        anchorEl={song?.customPopover}
                                                        getContentAnchorEl={null}
                                                        onClose={() => this.handleSongDataChange("customPopover", null, index)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                width: '30%',
                                                                borderRadius: '1px',
                                                            }
                                                        }}
                                                    >
                                                        <BasicTextArea
                                                            label={""}
                                                            rows={5}
                                                            value={this.state.customNotes || null}
                                                            onChange={(e) =>
                                                                this.setState({ customNotes: e.target.value?.length > 0 ? e.target.value : null })
                                                            }
                                                        />
                                                        <MDBRow className="mt-2">
                                                            <MDBCol md={8}>
                                                                <BasicButton
                                                                    text={"Sync Use Only"}
                                                                    onClick={() => {
                                                                        let currentString = this.state.customNotes
                                                                        currentString = currentString ? currentString?.includes('SYNC:') ? currentString : 'SYNC: ' + currentString : 'SYNC: '
                                                                        this.setState({ customNotes: currentString })
                                                                    }}
                                                                />
                                                            </MDBCol>
                                                            <MDBCol md={4}>
                                                                <BasicButton
                                                                    text={"OK"}
                                                                    onClick={() => {
                                                                        this.handleSongDataChange("custom_use", this.state.customNotes, index)
                                                                    }}
                                                                />
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow className="mt-2">
                                                            <MDBCol md={8}>
                                                                <BasicButton
                                                                    text={"Master Use Only"}
                                                                    onClick={() => {
                                                                        let currentString = this.state.customNotes
                                                                        currentString = currentString ? currentString?.includes('MASTER:') ? currentString : currentString + '\nMASTER: ' : '\nMASTER: '
                                                                        this.setState({ customNotes: currentString })
                                                                    }}
                                                                />
                                                            </MDBCol>
                                                            <MDBCol md={4}>
                                                                <BasicButton onClick={() => this.handleSongDataChange("customPopover", null, index)} variant="outlined" text={"Cancel"} />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </Popover>
                                                </MDBCol>}
                                            <MDBCol md={2} id="music_use">
                                                <SelectField
                                                    label={`${index === 0 ? "Use" : ""}`}
                                                    isMandatory={true}
                                                    showMandatory={this.state.postInitiated}
                                                    mandatoryWarning={messages.mandatoryWarning}
                                                    placeHolderText={"- Select - "}
                                                    value={song.music_uses_id}
                                                    options={this.state.useOptions || []}
                                                    onChange={(e) => this.handleSongDataChange("music_uses_id", e.target.value, index)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={1} className={index === 0 ? "align-end" : "self-center"}>
                                                <div className="add-remove-icons">
                                                    <div>
                                                        {!(data.songs_list.length === 1)
                                                            ? <RemoveCircleIcon fontSize="small" onClick={() => this.removeSong(index)} />
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="ml5">
                                                        {
                                                            data.songs_list.length - 1 === index && song.song_id != null ?
                                                                <AddCircleIcon fontSize="small" onClick={() => this.addSong(index)} />
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </MDBCol>

                                        </MDBRow>
                                    ))}
                                <MDBRow className="mt-2">
                                    <MDBCol md={5}>
                                        <BasicTextArea
                                            label={"Scene"}
                                            value={data.scene}
                                            onChange={(e) => this.handleChange("scene", e.target.value?.length > 0 ? e.target.value : null)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={5}>
                                        <MDBRow className="mt-2">
                                            <MDBCol md={6} className="self-end">
                                                {this.state.checkBoxOptionsLeft.map((obj, index) => (
                                                    <BasicCheckbox key={index}
                                                        id={obj.id}
                                                        label={obj.label}
                                                        checked={data[obj.id] === 1}
                                                        onChange={(e) => this.onCheckboxChange(e.target.checked, obj.id)}
                                                    />
                                                ))}
                                            </MDBCol>
                                            <MDBCol md={6} className="self-end">
                                                {this.state?.isActiveMusicSupervisor && checkBoxOptionsRight.map((obj, index) => (
                                                    <BasicCheckbox key={index}
                                                        id={obj.id}
                                                        label={obj.label}
                                                        checked={data[obj.id] ? true : false}
                                                        onChange={(e) => this.onCheckboxChange(e.target.checked, obj.id)}
                                                        disabled={obj.id === "is_apply_dollar_to_HV" ? !editApplyHVPermission : false}
                                                    />
                                                ))}
                                                {!this.state?.isActiveMusicSupervisor && checkBoxOptionsRight.filter(obj => obj.id !== "is_ms_complete").map((obj, index) => (
                                                    <BasicCheckbox key={index}
                                                        id={obj.id}
                                                        label={obj.label}
                                                        checked={data[obj.id] ? true : false}
                                                        onChange={(e) => this.onCheckboxChange(e.target.checked, obj.id)}
                                                        disabled={obj.id === "is_apply_dollar_to_HV" ? !editApplyHVPermission : false}
                                                    />
                                                ))}
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                    <MDBCol md={2} >
                                        <BasicLabel text="Ad Card" />
                                        <SelectField
                                            placeHolderText="-Select-"
                                            value={data.clearance_option_id || []}
                                            options={this.state.adCardOptions || []}
                                            onChange={(e, newValue) => {
                                                this.handleChange("clearance_option_id", e.target.value);
                                                this.handleChange("clearance_option", newValue?.props?.children)
                                            }
                                            }
                                        />
                                    </MDBCol>

                                </MDBRow>
                                <br />
                                <MDBRow>
                                    <MDBCol md={10}></MDBCol>
                                    <MDBCol md={2}>
                                        <MDBRow>
                                            <MDBCol>
                                                <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props?.mode === "Edit" ? "Save" : "Create"} onClick={this.handleModalAndSubmit} disabled={this.props?.viewNewCRPermission?.edit === 1 ? false : true} />
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicButton onClick={() => this.props?.handleClose()} variant="outlined" text={"Cancel"} />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </div>
                    )
                }
                <Popover
                    open={this.state.newSongPopup}
                    getContentAnchorEl={null}
                    onClose={() => this.newSongPopup()}
                    anchorOrigin={{
                        vertical: '5%',
                        horizontal: 'center',
                    }}
                    anchorPosition={{ top: 100 }}
                    transformOrigin={{
                        vertical: '5%',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            width: '70%',
                            borderRadius: '1px',
                            padding:'0px'
                        }
                    }}
                >
                    <EditSongDetails
                        songId={this.props?.songId}
                        mode={"new"}
                        fieldChanged={this.props?.fieldChanged}
                        setPostFlag={this.props?.setPostFlag}
                        handleClose={this.newSongPopup.bind(this)}
                        notificationComponent={this.props?.notificationComponent}
                        toggleRefreshPage={this.props?.toggleRefreshPage}
                        //toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
                        PopoverTitle={"New Song"}
                        songModalPermissions={this.props?.episodeSongPermissions ? this.props?.episodeSongPermissions : this.props?.commonPermissions?.find(item => item.permission_group_name == "Songs")}
                        proposedNewSong={this.state.proposedNewSong?.value}
                        newSongId={this.newSongId.bind(this)}
                        newSongRowIndex={this.state.newSongRowIndex}
                        origin="clearanceRecord"
                    />
                </Popover>
            </>
        );
    }
}
export default withUserContext(ClearanceRecord);
